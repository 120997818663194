import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'


// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"
// import "@wordpress/block-library/build-style/gallery/style.css"
// import "@wordpress/block-library/build-style/gallery/theme.css"

import "@wordpress/block-library/build-style/cover/style.css";
import "@wordpress/block-library/build-style/columns/style.css";



import Seo from "../components/seo"
import BlogCTA from "../components/BlogCTA"

const getFeatureImage = (post) => {
  if (typeof post.featuredImage !== 'undefined') {
    return {
      data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
      alt: post.featuredImage?.node?.alt || ``,
    }
  } else {
    return null
  }
}

const TagHolder = ({
  name,
  children,
  color = 'gray-400'
}) => {
  return (
    <span key={name} className={`flex border-${color} px-2 py-1 text-${color}`}>
      {children}
    </span>
  )
}

const BlogPostTemplate = ({ data: { previous, next, post } }) => {

  const featuredImage = getFeatureImage(post)
  const tagNodes = post.tags.nodes;
  const cat = post.categories.nodes[0].name;

  return (
    <div>
      <Seo title={post.title} description={post.excerpt} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className="relative">
        {/* max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 */}

          <div className="mt-8 lg:mt-0">

            <div className="text-lg text-gray-900 max-w-prose mx-auto lg:max-w-none">
              {!!post.content && (
                <section itemProp="articleBody" className="flex flex-col items-center">
                  {parse(post.content)}
                </section>
              )}
            </div>
          </div>
        </div>

        <footer className="flex flex-col items-center text-gray-400 my-24">
          <div className="font-light flex gap-x-2 py-2">
            <TagHolder
              name={cat}>
              {cat}
            </TagHolder>
            {tagNodes.map(tag => (
              <TagHolder
                name={tag.name}
              >
                {`#${tag.name}`}
              </TagHolder>
            ))}
          </div>
          <span
            className="font-light flex items-center justify-center mt-2"
          >
            <CalendarIcon
              className="h-5 w-5 mr-2 -mt-1"
            />
            {post.date}
          </span>
          {/* <div className="mx-auto text-base max-w-prose">
              <h1
                className="mt-2 text-3xl leading-8 text-left  tracking-tight font-heading text-gray-900 sm:text-4xl"
                itemProp="headline">
                {parse(post.title)}
              </h1>
            </div> */}

          {/* <div className="text-lg text-gray-900 max-w-prose mx-auto lg:max-w-none">
              {!!post.excerpt && (
                <section itemProp="articleBody">{parse(post.excerpt)}</section>
              )}
            </div>
            {featuredImage && featuredImage?.data && (
              <GatsbyImage
                className="shadow-lg w-full max-w-prose"
                imgClassName=""
                image={featuredImage.data}
                alt={featuredImage.alt}
                style={{ marginBottom: 50 }}
              />
            )} */}
        </footer>

        {/* <BlogCTA /> */}


      </article>

      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <nav className="blog-post-nav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link
                  className="flex items-center"
                  to={`/blog${previous.uri}`}
                  rel="prev"
                >
                  <ChevronLeftIcon
                    className="h-10 w-10"
                  />
                  <span
                    className="text-xl"
                  >
                    {parse(previous.title)}
                  </span>
                </Link>
              )}
            </li>

            <li>
              {next && (
                <Link
                  className="flex items-center"
                  to={`/blog${next.uri}`}
                  rel="next"
                >
                  <span
                    className="text-xl"
                  >
                    {parse(next.title)}
                  </span>
                  <ChevronRightIcon
                    className="h-10 w-10"
                  />
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>

    </div>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
      date(formatString: "DD. MMMM YYYY", locale: "de")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
                gatsbyImageData(
                    width: 800, 
                    height: 800,
                    placeholder: BLURRED
                )                              
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`